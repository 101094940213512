import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/koen-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/koen"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">
      <b>Fundamentals</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Who’s who and does what in markets.
        </li>
        <li>
          Global markets overview and evolution.
        </li>
        <li>
          Data importance, how to find and use them.
        </li>
        <li>
          Understanding central banks and other major trend drivers.
        </li>
        <li>
          Developed and emerging markets study.
        </li>
      </ul>

      <b>Psychology and profile</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Building, defining who you are as a trader.
        </li>
        <li>
          Prepare strategies depending on your type of trader, investor.
        </li>
        <li>
          Build a solid risk management, discipline base.
        </li>
        <li>
          Management of emotions, positive and negative.
        </li>
      </ul>

      <b>Trading strategies and techniques</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          The A-Z of planning trades.
        </li>
        <li>
          Short, medium, long term trade plans and techniques.
        </li>
        <li>
          Correlations.
        </li>
        <li>
          Building a trading book and how to manage it.
        </li>
        <li>
          Basic techs and how I use them (TL, Fibs, Ichimoku..).
        </li>
        <li>
          How to use flows, fixed time events (expiries, fixings..) to one’s advantage.
        </li>
      </ul>

      <b>FX Options strategies (requires some experience)</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          What impacts pricing.
        </li>
        <li>
          Greeks.
        </li>
        <li>
          Strategies mainly based on FX, will look at other markets on request.
        </li>
        <li>
          How I incorporate them into my global trading.
        </li>
      </ul>

    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Koen Vanderschrick",
    firstName: "Koen",
    twitter: "@KVanderschrick",
    headerText: "Struggling with FX markets, cash or options? My course is what you need to get back on top.",
    videoUrlCode: "3yHuJw8lnqI",
  }
  return (
    <>
      <Seo title='Trader Coach Koen' />
      <GDPRConsent />
      <Header props={mentor} />
      <main>
        <>
          {<DiscountBanners mentor='koen' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                  src={imgProfile}
                  alt={'Koen'}
                  />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">Foreign Exchange Manager at Independent Forex Trader</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                Koen's skills range from extended fundamentals to markets psychology into basic technical short and long term trading setups.
                <br /><br />

                Having been on both sides of the spectrum over my 40+ years career in major banks as a long time FX market maker to the biggest clients, an FX desk head and as a manager of my own and client funds thereafter, I have experience with every aspect of the market, every size or duration of positions and support of traders.
                <br />
                <br />
                People interested in my coaching can range from beginnners to already experienced traders, adapted to the individual needs. The coverage will go from basic market fundamentals to in depth studies of economic situations, correlations, FX options if required, trade setups based on bias, timing. The in depth studying may require more than one session, to be discussed at the time of interest in my helping you on your path.

              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>

          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $150</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $250.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=58719774"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="koen">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=58719774" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
              <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
